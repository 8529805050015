<template>
  <el-main class="serviceList">
    <el-button style="margin-bottom: 10px" type="primary" size="small" @click="addCustomFlag = true">添加客服</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="客服昵称：">
        <el-input size="small" v-model="search.nickname" placeholder="请输入客服昵称/电话/姓名"></el-input>
      </el-form-item>
      <el-form-item label="店铺名称：">
        <el-select size="small" v-model="search.store_id" placeholder="请选择店铺">
          <el-option v-for="item in storeList" :key="item.value" :label="item.store_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间：">
        <el-date-picker size="small" v-model="search.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getServiceList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="serviceList" height="100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="店员信息" align="center">
        <template v-slot="{ row }">
          <div class="infoBox">
            <img :src="row.avatar" alt="" />
            <p>{{ row.nickname }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="店员姓名" align="center" width="100px"></el-table-column>
      <el-table-column prop="mobile" label="手机号码" align="center" width="150px"></el-table-column>
      <el-table-column prop="store_name" label="店铺名称" align="center" width="250px"></el-table-column>
      <el-table-column label="提交时间" align="center" width="250px">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="updateItem(row)">编辑</el-button>
          <el-button type="text" size="small" @click="delItem(row)">删除</el-button>
          <el-button type="text" size="small" @click="goService(row)">进入后台</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="row" @updatePageNum="updateData"></Paging>
    <el-dialog :title="isUpdate ? '编辑客服' : '添加客服'" :visible.sync="addCustomFlag" width="52%" @closed="dialogClose">
      <el-form ref="addMan" :label-position="labelPosition" label-width="120px" :model="addMan" :rules="rules" class="formDia">
        <el-form-item label="昵称：" prop="nickname">
          <el-input v-model="addMan.nickname"></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <ReadyUploadSource
            :showStyle="{
              width: '100px',
              height: '100px',
            }"
            @getSource="getImg"
            @removeThis="removeImg"
            :path="addMan.avatar"
          ></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="客服名称：" prop="name">
          <el-input v-model="addMan.name"></el-input>
        </el-form-item>
        <el-form-item label="所属门店：">
          <el-select v-model="addMan.store_id" placeholder="请选择">
            <el-option v-for="item in storeList" :key="item.value" :label="item.store_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="addMan.mobile"></el-input>
        </el-form-item>
        <el-form-item label="密码：" :prop="!isUpdate ? 'password' : ''">
          <el-input type="password" v-model="addMan.password" :placeholder="!isUpdate ? '请输入密码' : '请输入新密码'"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCustomFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureAdd">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
import { ServicePath } from '../../util/config';
export default {
  components: {
    ReadyUploadSource,
    Paging,
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (myreg.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号'));
      }
    };
    return {
      addCustomFlag: false,
      addMan: {
        name: '',
        nickname: '',
        avatar: '',
        mobile: '',
        password: '',
        store_id: '',
      },
      rules: {
        name: [{ required: true, message: '请输入客服名称', trigger: 'blur' }],
        nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        avatar: [{ required: true, message: '请选择头像', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      search: {
        nickname: '',
        store_id: '',
        time: [],
      },
      storeList: [],
      serviceList: [],
      page: 1,
      row: 10,
      total: 0,
      first: true,
      isUpdate: false,
    };
  },
  created() {
    this.getServiceList();
  },
  methods: {
    goService(row) {
      window.open(`${ServicePath}#/login?account=${row.mobile}`);
    },
    // 获取头像
    getImg(item) {
      this.addMan.avatar = item.path;
    },
    removeImg() {
      this.addMan.avatar = '';
    },
    // 确认添加客服
    sureAdd() {
      if (!this.addMan.avatar) {
        this.$message.warning('请上传一张头像');
        return;
      }
      let that = this;
      if (this.isUpdate) {
        //修改
        let obj = {
          id: this.addMan.id,
          name: this.addMan.name,
          nickname: this.addMan.nickname,
          avatar: this.addMan.avatar,
          mobile: this.addMan.mobile,
          password: this.addMan.password,
          store_id: this.addMan.store_id,
        };
        if (obj.password == '') {
          delete obj.password;
        }
        if (!obj.store_id) {
          obj.store_id = 0;
        }
        that.$axios.post(that.$api.set.editCustomer, obj).then(res => {
          if (res.code == 0) {
            this.$message.success('修改成功');
            this.getServiceList();
            this.addCustomFlag = false;
          } else {
            this.$message.error(res.msg);
          }
        });
        return;
      }
      this.$refs.addMan.validate(valid => {
        if (valid) {
          that.addMan.store_id = that.addMan.store_id == '' ? 0 : that.addMan.store_id;
          that.$axios.post(that.$api.set.addCustomer, that.addMan).then(res => {
            if (res.code == 0) {
              that.$message.success('添加成功');
              that.addCustomFlag = false;
              that.getServiceList();
            } else {
              that.$message.error(`${res.msg}`);
            }
          });
        } else {
          return false;
        }
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    // 清空搜索条件
    clearSearch() {
      this.search = {
        nickname: '',
        store_id: '',
        time: [],
      };
      this.getServiceList(1);
    },
    // 获取所有的门店列表
    getStoreList() {
      let that = this;
      this.$axios.post(this.$api.set.storeList).then(res => {
        if (res.code === 0) {
          that.storeList = res.result;
          if (that.first) {
            console.log(that.storeList);
            that.first = false;
            that.serviceList.map(item => {
              if (item.store_id === 0) {
                that.$set(item, 'store_name', '未绑定门店');
              } else {
                that.$set(item, 'store_name', that.storeList.find(item1 => item1.id == item.store_id).store_name);
              }
            });
          }
        }
      });
    },
    // 获取客服列表
    getServiceList(isSearch) {
      if (isSearch) {
        this.page = 1;
      }
      let that = this;
      this.$axios
        .post(this.$api.set.serviceList, {
          keywords: this.search.nickname,
          page: this.page,
          rows: this.row,
          start_time: this.search.time ? this.search.time[0] : null,
          end_time: this.search.time ? this.search.time[1] : null,
          store_id: !this.search.store_id ? 0 : this.search.store_id,
        })
        .then(res => {
          if (res.code == 0) {
            that.serviceList = res.result.list;
            that.total = res.result.total_number;
            if (that.first) {
              that.getStoreList();
            } else {
              that.serviceList.map(item => {
                if (item.store_id === 0) {
                  that.$set(item, 'store_name', '未绑定门店');
                } else {
                  that.$set(item, 'store_name', that.storeList.find(item1 => item1.id == item.store_id).store_name);
                }
              });
            }
          }
        });
    },
    updateData(val, status) {
      if (status == 0) {
        this.row = val;
      } else {
        this.page = val;
      }

      this.getServiceList();
    },
    dialogClose() {
      this.addMan = { name: '', nickname: '', avatar: '', mobile: '', password: '', store_id: '' };
      this.isUpdate = false;
    },
    //编辑该客服
    updateItem(item) {
      this.addMan = item;
      this.addMan.password = '';
      this.addMan.store_id = this.addMan.store_id === 0 ? '' : this.addMan.store_id;
      this.isUpdate = true;
      this.addCustomFlag = true;
    },
    // 删除客服
    delItem(item) {
      let that = this;
      this.$confirm('此操作将永久删除该客服账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return that.$axios.post(that.$api.set.delCustomer, {
            id: item.id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            that.$message.success('删除成功');
            that.getServiceList();
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
  watch: {
    'search.time'(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="less" scoped>
.serviceList {
  width: 100%;
  background-color: #fff;
  .formDia {
    .el-input {
      width: 50% !important;
    }
  }
}
.infoBox {
  display: flex;
  align-items: center;
  padding-left: 30%;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.el-table {
  height: calc(100% - 180px) !important;
  & /deep/ .el-table__body-wrapper {
    height: calc(100% - 48px) !important;
  }
}
</style>
